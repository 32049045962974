// extracted by mini-css-extract-plugin
export var root = "carousel-module--root--yMStl";
export var outer = "carousel-module--outer--mcW30";
export var slideTrack = "carousel-module--slide-track--k3yN+";
export var inner = "carousel-module--inner--q4ANp";
export var arrowButtonLeft = "carousel-module--arrowButtonLeft--+A05P";
export var arrowButtonRight = "carousel-module--arrowButtonRight--afEBM";
export var figure = "carousel-module--figure--CyEFY";
export var embed = "carousel-module--embed--FYPP5";
export var pdf = "carousel-module--pdf--uqqwM";
export var noCarousel = "carousel-module--noCarousel--KHJGe";
export var wrapper = "carousel-module--wrapper--Y1EnM";
export var simpleImage = "carousel-module--simpleImage--Yn22e";
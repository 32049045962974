// extracted by mini-css-extract-plugin
export var header = "navigation-module--header--os5PY";
export var menu = "navigation-module--menu--9qD7o";
export var sanity = "navigation-module--sanity--LDChG";
export var mobileHeader = "navigation-module--mobileHeader--bqtlo";
export var wrapper = "navigation-module--wrapper--CL+iS";
export var logo = "navigation-module--logo--wtPTJ";
export var osun = "navigation-module--osun--uZh0P";
export var searchWrapper = "navigation-module--searchWrapper--84SRm";
export var langWrapper = "navigation-module--langWrapper--Mo7iC";
export var globe = "navigation-module--globe--55ID7";
export var lang = "navigation-module--lang--AVzx2";
export var close = "navigation-module--close---hjna";
export var email = "navigation-module--email--SlqUk";